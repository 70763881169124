<script>
import Layout from "@/layouts/main.vue";
import appConfig from "@/app.config";
import "@vueform/multiselect/themes/default.css";
import PageHeader from "@/components/page-header";
import animationData from "@/components/widgets/msoeawqm.json";
import Lottie from "@/components/widgets/lottie.vue";
import _ from "lodash";

import exportExcel from "@/helpers/exportExcel.js";

export default {
  page: {
    title: "Regions",
    meta: [
      {
        name: "Regions",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      modalTitle: "",
      fileList: {},
      checkAll: false,
      title: "Regions List",
      items: [
        {
          text: "Regions",
          href: "/",
        },
        {
          text: "Regions List",
          active: true,
        },
      ],
      searchQuery: null,
      defaultOptions: {
        animationData: animationData,
      },
      sliderCustomzie: [1000, 3000],
      userData: [],
      pages: [],
      page: 1,

      UserDetailsData: {},
      formData: {
        name: "",

        avatar: "",
        phone: "",

        email: "",
        role_id: "",
      },
      table_options: {
        current_page: 1,
        page_size: 20,
        search_text: "",
        sort: { column: "", direction: "" },
      },
      meta: {},


    };
  },

  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  methods: {

    exportUsers() {
      this.$store.dispatch("region/exportRegions").then((res) => {
        exportExcel(res, "user-list.xlsx");
      });
    },


    openEditClient(data) {
      this.modalTitle = "Edit User";
      this.formData = {
        ...data,
      };
    },
    //add and edit User data
    openUserModal() {
      Object.keys(this.formData).forEach((key) => (this.formData[key] = ""));
      this.modalTitle = "Add User";
    },

    //get all Users
    getUsers() {
      this.$store
        .dispatch("region/getRegions", this.table_options)
        .then((res) => {
          console.log(res);
          this.userData = res.data;
          this.meta.total = res.total;
          this.meta.from = res.from;
          this.meta.last_page = res.last_page;
        })
        .catch((err) => console.log(err));
    },

    debounceInput: _.debounce(function (e) {
      this.table_options.search_text = e.target.value;
      this.$store
        .dispatch("region/getRegions", this.table_options)
        .then((response) => {
          this.userData = response.data;
          this.meta.total = response.total;
          this.meta.from = response.from;
          this.meta.last_page = response.last_page;
        });
    }, 500),

    handlePageChange(e) {
      this.table_options.current_page = e;
      this.$store
        .dispatch("region/getRegions", this.table_options)
        .then((response) => {
          this.userData = response.data;
          this.meta.total = response.total;
          this.meta.from = response.from;
          this.meta.last_page = response.last_page;
        });
    },
  },
  mounted() {
    this.getUsers();
  },

  components: {
    Layout,
    PageHeader,
    lottie: Lottie,

  },
};
</script>

<template>
  <Layout>
    <!-- end table responsive -->
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-12">
        <div>
          <div class="card">
            <div class="card-header border-0">
              <div class="row g-4">
                <div class="col-sm-auto">
                  <div>
                    <button
                      type="button"
                      @click="exportUsers"
                      class="btn btn-primary m-2 add-btn"
                    >
                      Export
                    </button>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="d-flex justify-content-sm-end">
                    <div class="search-box ms-2">
                      <input
                        type="text"
                        @input="debounceInput"
                        class="form-control"
                        placeholder="Search ..."
                      />
                      <i class="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <ul
                    class="nav nav-tabs-custom card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <li class="nav-item">
                      <a
                        class="nav-link active fw-semibold"
                        data-bs-toggle="tab"
                        href="#Usernav-all"
                        role="tab"
                      >
                        All

                        <span
                          class="badge badge-soft-danger align-middle rounded-pill ms-1"
                          >{{ meta.total }}</span
                        >
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-auto">
                  <div id="selection-element">
                    <div class="my-n1 d-flex align-items-center text-muted">
                      Select
                      <div
                        id="select-content"
                        class="text-body fw-semibold px-1"
                      ></div>
                      Result
                      <button
                        type="button"
                        class="btn btn-link link-danger p-0 ms-3 shadow-none"
                        data-bs-toggle="modal"
                        data-bs-target="#removeItemModal"
                        @click="deleteMultiple"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card header -->
            <div class="card-body">
              <div class="tab-content text-muted">
                <div class="tab-pane active" id="Usernav-all" role="tabpanel">
                  <div
                    id="table-User-list-all "
                    class="table-card gridjs-border-none table-responsive"
                  >
                    <table
                      class="table align-middle table-nowrap mb-0"
                      id="customerTable"
                    >
                      <thead class="table-light">
                        <tr>
                          <th scope="col" style="width: 50px">

                          </th>
                          <th>Region ID</th>

                          <th>Region Name</th>
                          <th>Short</th>
                          <th>Region</th>
                          <th>Population</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody class="list form-check-all">
                        <tr
                          class="gridjs-tr"
                          v-for="(data, index) of userData"
                          :key="index"
                        >
                          <td
                            data-column-id="UserListAllCheckbox"
                            class="gridjs-td"
                          >

                          </td>
                          <td class="id" hidden>
                            <a href="">{{ data.id }}</a>
                          </td>
                          <td data-column-id="name" class="gridjs-td">
                            {{ data.regionid }}
                          </td>

                          <td data-column-id="email" class="gridjs-td">
                            {{ data.region }}
                          </td>
                          <td data-column-id="phone" class="gridjs-td">
                            {{ data.regionshort }}
                          </td>
                          <td data-column-id="role" class="gridjs-td">
                                                      {{ data.regionfolder }}
                          </td>
                          <td data-column-id="status_id" class="gridjs-td">

                              {{ data.regionpopulation }}

                          </td>
                          <td data-column-id="action" class="gridjs-td">

                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      @click="handlePageChange(table_options.current_page)"
                      class="d-flex mt-4 mx-3 align-items-center justify-content-end"
                    >
                      <b-pagination
                        v-model="table_options.current_page"
                        :total-rows="meta.total"
                        :per-page="table_options.page_size"
                        prev-text="Prev"
                        next-text="Next"
                        prev-class="prev"
                        next-class="next"
                        class="pagination-wrap hstack gap-2"
                      >
                        <template #prev-text>
                          <div
                            class="page-item shadow-sm pagination-prev disabled"
                          >
                            Previous
                          </div>
                        </template>

                        <template #next-text>
                          <div class="page-item shadow-sm pagination-next">
                            Next
                          </div>
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </div>
                <!-- end tab pane -->

                <div class="tab-pane" id="Usernav-published" role="tabpanel">
                  <div
                    id="table-User-list-published"
                    class="table-card gridjs-border-none"
                  ></div>
                </div>
                <!-- end tab pane -->

                <div class="tab-pane" id="Usernav-draft" role="tabpanel">
                  <div class="py-4 text-center">
                    <div>
                      <lottie
                        class="avatar-xl"
                        colors="primary:#121331,secondary:#08a88a"
                        :options="defaultOptions"
                        :height="75"
                        :width="75"
                      />
                    </div>

                    <div class="mt-4">
                      <h5>Sorry! No Result Found</h5>
                    </div>
                  </div>
                </div>
                <!-- end tab pane -->
              </div>
              <!-- end tab content -->
            </div>
            <!-- end card body -->
          </div>
          <!-- end card -->
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
